<template>
    <div>
        <div class="pb-4 border-dotted border-b-2">
            <div class="text-tecis-blau text-xl font-bold">
                Mögliches Ablaufergebnis
            </div>
            bei
            <span class="text-tecis-limette font-semibold"
                >{{ old_discount_rate }}%</span
            >
            Rechnungszins:
            <div>{{ convert(getProcessResultOld) }} €</div>
        </div>

        <div class="pb-4 pt-2 border-dotted border-b-2">
            <div class="text-tecis-blau text-xl font-bold">
                Mögliche Veränderung
            </div>
            bei
            <span class="text-tecis-limette font-semibold"
                >{{ new_discount_rate }}%</span
            >
            Rechnungszins:

            <div>
                <div class="pt-4">
                    <div class="text-tecis-blau text-xl font-bold">
                        Ablaufergebnis
                    </div>
                    <div>{{ convert(getProcessResultNew) }} €</div>
                </div>
                <div class="pt-4">
                    <div class="text-tecis-blau text-xl font-bold">Verlust</div>
                    <div>{{ convert(getLoss) }} €</div>
                </div>
            </div>
        </div>

        <div class="pb-4 pt-2">
            <div class="text-tecis-blau text-xl font-bold">
                Benötigter Mehrbetrag,
            </div>
            <div>um das heute mögliche Ergebnis zu erzielen:</div>
            <div class="text-tecis-limette font-bold text-2xl">
                {{ convert(getRequiredAdditionalAmountResult) }} €
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    Name: 'ResultForm',
    computed: {
        ...mapGetters([
            'getProcessResultOld',
            'getProcessResultNew',
            'getLoss',
            'getRequiredAdditionalAmountResult'
        ]),
        old_discount_rate: {
            get() {
                return this.$store.state.old_discount_rate;
            }
        },
        new_discount_rate: {
            get() {
                return this.$store.state.new_discount_rate;
            }
        }
    },
    methods: {
        convert(num) {
            return (Math.round(parseFloat(num) * 100) / 100).toLocaleString(
                'de-DE',
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                }
            );
        }
    }
};
</script>

<style></style>
