<template>
    <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6">
            <label
                for="entry-age"
                class="block text-sm font-medium text-gray-700"
                >Eintrittsalter</label
            >
            <input
                type="number"
                v-model.number="entry_age"
                id="entry-age"
                class="
                    mt-1
                    focus:ring-tecis-himmelblau focus:border-tecis-himmelblau
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                "
                required
            />
        </div>

        <div class="col-span-6">
            <label
                for="retirement-age"
                class="block text-sm font-medium text-gray-700"
                >Rentenalter</label
            >
            <input
                type="number"
                v-model.number="retirement_age"
                id="retirement-age"
                class="
                    mt-1
                    focus:ring-tecis-himmelblau focus:border-tecis-himmelblau
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                "
                required
            />
        </div>

        <div class="col-span-6">
            <label
                for="monthly-fee"
                class="block text-sm font-medium text-gray-700"
                >Monatsbeitrag</label
            >
            <div class="mt-1 flex rounded-md shadow-sm">
                <input
                    type="number"
                    step="0.01"
                    v-model.number="monthly_fee"
                    id="monthly-fee"
                    class="
                        focus:ring-tecis-himmelblau
                        focus:border-tecis-himmelblau
                        flex-1
                        block
                        w-full
                        rounded-none rounded-l-md
                        sm:text-sm
                        border-gray-300
                    "
                    required
                />
                <span
                    class="
                        inline-flex
                        items-center
                        px-3
                        rounded-r-md
                        border border-l-0 border-gray-300
                        bg-gray-50
                        text-gray-500 text-sm
                    "
                >
                    €
                </span>
            </div>
        </div>

        <div class="col-span-6">
            <label
                for="return-savings-pot"
                class="block text-sm font-medium text-gray-700"
                >Rendite Spartopf</label
            >
            <div class="mt-1 flex rounded-md shadow-sm">
                <input
                    type="number"
                    step="0.01"
                    v-model.number="return_savings_pot"
                    id="return-savings-pot"
                    class="
                        focus:ring-tecis-himmelblau
                        focus:border-tecis-himmelblau
                        flex-1
                        block
                        w-full
                        rounded-none rounded-l-md
                        sm:text-sm
                        border-gray-300
                    "
                    required
                />
                <span
                    class="
                        inline-flex
                        items-center
                        px-3
                        rounded-r-md
                        border border-l-0 border-gray-300
                        bg-gray-50
                        text-gray-500 text-sm
                    "
                >
                    %
                </span>
            </div>
        </div>
        <div class="col-span-6">
            <label
                for="old-discount-rate"
                class="block text-sm font-medium text-gray-700"
                >Alter Rechnungszins</label
            >
            <div class="mt-1 flex rounded-md shadow-sm">
                <input
                    type="number"
                    step="0.01"
                    v-model.number="old_discount_rate"
                    id="old-discount-rate"
                    class="
                        focus:ring-tecis-himmelblau
                        focus:border-tecis-himmelblau
                        flex-1
                        block
                        w-full
                        rounded-none rounded-l-md
                        sm:text-sm
                        border-gray-300
                    "
                    required
                    disabled
                />
                <span
                    class="
                        inline-flex
                        items-center
                        px-3
                        rounded-r-md
                        border border-l-0 border-gray-300
                        bg-gray-50
                        text-gray-500 text-sm
                    "
                >
                    %
                </span>
            </div>
        </div>
        <div class="col-span-6">
            <label
                for="new-discount-rate"
                class="block text-sm font-medium text-gray-700"
                >Neuer Rechnungszins</label
            >
            <div class="mt-1 flex rounded-md shadow-sm">
                <input
                    type="number"
                    step="0.01"
                    v-model.number="new_discount_rate"
                    id="new-discount-rate"
                    class="
                        focus:ring-tecis-himmelblau
                        focus:border-tecis-himmelblau
                        flex-1
                        block
                        w-full
                        rounded-none rounded-l-md
                        sm:text-sm
                        border-gray-300
                    "
                    required
                    disabled
                />
                <span
                    class="
                        inline-flex
                        items-center
                        px-3
                        rounded-r-md
                        border border-l-0 border-gray-300
                        bg-gray-50
                        text-gray-500 text-sm
                    "
                >
                    %
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputForm',
    computed: {
        entry_age: {
            get() {
                return this.$store.state.entry_age;
            },
            set(value) {
                this.$store.commit('SET_ENTRY_AGE', parseInt(value));
            }
        },
        retirement_age: {
            get() {
                return this.$store.state.retirement_age;
            },
            set(value) {
                this.$store.commit('SET_RETIREMENT_AGE', parseInt(value));
            }
        },
        monthly_fee: {
            get() {
                return this.$store.state.monthly_fee;
            },
            set(value) {
                this.$store.commit('SET_MONTHLY_FEE', parseFloat(value));
            }
        },
        return_savings_pot: {
            get() {
                return this.$store.state.return_savings_pot;
            },
            set(value) {
                this.$store.commit('SET_RETURN_SAVINGS_POT', parseFloat(value));
            }
        },
        old_discount_rate: {
            get() {
                return this.$store.state.old_discount_rate;
            },
            set(value) {
                this.$store.commit('SET_OLD_DOSCOUNT_RATE', parseFloat(value));
            }
        },
        new_discount_rate: {
            get() {
                return this.$store.state.new_discount_rate;
            },
            set(value) {
                this.$store.commit('SET_NEW_DOSCOUNT_RATE', parseFloat(value));
            }
        }
    }
};
</script>

<style></style>
