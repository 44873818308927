<template>
    <div class="fixed top-0 w-full z-50 bg-white">
        <div
            class="
                flex
                justify-between
                items-center
                border-b-2 border-gray-100
                py-6
                md:justify-start md:space-x-10
            "
        >
            <div class="flex justify-center flex-1 items-center">
                <a href="#" class="flex">
                    <span class="sr-only">SiReZi</span>
                    <img
                        class="h-8 w-auto sm:h-10"
                        src="/img/icons/tecis-430x500.png"
                        alt=""
                    />
                </a>
                <div class="ml-4 text-lg text-tecis-blau">
                    SiReZi {{ nextYear }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppHeader',
    computed: {
        nextYear() {
            return new Date().getFullYear() + 1;
        }
    }
};
</script>
