import { createStore } from 'vuex';
import excel from '@/utils/excel.js';

export default createStore({
    state: {
        entry_age: 25,
        retirement_age: 67,
        monthly_fee: 100,
        return_savings_pot: 6,
        old_discount_rate: 0.9,
        new_discount_rate: 0.25
    },
    getters: {
        getProcessResult: (state) => (discount_rate) => {
            let diff_age = state.retirement_age - state.entry_age;
            return (
                diff_age * 12 * state.monthly_fee +
                excel.zw(
                    state.return_savings_pot / 100 / 12,
                    diff_age * 12,
                    -state.monthly_fee -
                        excel.rmz(
                            discount_rate / 12,
                            diff_age * 12,
                            0,
                            diff_age * 12 * state.monthly_fee
                        )
                )
            );
        },
        getProcessResultOld: (state, getters) => {
            return getters.getProcessResult(state.old_discount_rate / 100);
        },
        getProcessResultNew: (state, getters) => {
            return getters.getProcessResult(state.new_discount_rate / 100);
        },
        getLoss: (state, getters) => {
            return getters.getProcessResultOld - getters.getProcessResultNew;
        },
        getRequiredAdditionalAmount:
            (state) => (loss, process_result_new, process_result_old) => {
                if (loss == 0) return 0;

                let diff_age = state.retirement_age - state.entry_age;
                return (
                    -excel.rmz(
                        -excel.zins(
                            diff_age * 12,
                            -state.monthly_fee,
                            process_result_new
                        ),
                        diff_age * 12,
                        0,
                        process_result_old
                    ) - state.monthly_fee
                );
            },
        getRequiredAdditionalAmountResult: (state, getters) => {
            return getters.getRequiredAdditionalAmount(
                getters.getLoss,
                getters.getProcessResultNew,
                getters.getProcessResultOld
            );
        }
    },
    mutations: {
        SET_ENTRY_AGE(state, entry_age) {
            state.entry_age = entry_age;
        },
        SET_RETIREMENT_AGE(state, retirement_age) {
            state.retirement_age = retirement_age;
        },
        SET_MONTHLY_FEE(state, monthly_fee) {
            state.monthly_fee = monthly_fee;
        },
        SET_RETURN_SAVINGS_POT(state, return_savings_pot) {
            state.return_savings_pot = return_savings_pot;
        },
        SET_OLD_DISCOUNT_RATE(state, old_discount_rate) {
            state.old_discount_rate = old_discount_rate;
        },
        SET_NEW_DISCOUNT_RATE(state, new_discount_rate) {
            state.new_discount_rate = new_discount_rate;
        }
    },
    actions: {
        SET_ENTRY_AGE({ commit }, entry_age) {
            commit('SET_ENTRY_AGE', entry_age);
        },
        SET_RETIREMENT_AGE({ commit }, retirement_age) {
            commit('SET_RETIREMENT_AGE', retirement_age);
        },
        SET_MONTHLY_FEE({ commit }, monthly_fee) {
            commit('SET_MONTHLY_FEE', monthly_fee);
        },
        SET_RETURN_SAVINGS_POT({ commit }, return_savings_pot) {
            commit('SET_RETURN_SAVINGS_POT', return_savings_pot);
        },
        SET_OLD_DISCOUNT_RATE({ commit }, old_discount_rate) {
            commit('SET_OLD_DISCOUNT_RATE', old_discount_rate);
        },
        SET_NEW_DISCOUNT_RATE({ commit }, new_discount_rate) {
            commit('SET_NEW_DISCOUNT_RATE', new_discount_rate);
        }
    },
    modules: {}
});
