<template>
    <div class="home">
        <Flicking ref="flick" :options="{ circular: true }">
            <div class="panel w-full" :key="0">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="px-4 py-5 bg-white sm:p-6">
                        <input-form />
                    </div>
                </div>
            </div>
            <div class="panel w-full" :key="1">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="px-4 py-5 bg-white sm:p-6">
                        <result-form />
                    </div>
                </div>
            </div>
            <div class="panel pie-panel w-full" :key="2">
                <div class="mt-5 md:mt-0 md:col-span-2 h-full">
                    <div class="px-4 py-5 bg-white sm:p-6 h-full">
                        <pie-chart
                            :chartData="chartData"
                            :options="options"
                            class="h-full"
                        />
                    </div>
                </div>
            </div>
        </Flicking>
    </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import Flicking from '@egjs/vue3-flicking';
import InputForm from '@/components/InputForm.vue';
import ResultForm from '@/components/ResultForm.vue';
import { PieChart } from 'vue-chart-3';
import { mapGetters } from 'vuex';
Chart.register(...registerables);

export default {
    components: {
        Flicking,
        InputForm,
        ResultForm,
        PieChart
    },
    name: 'Home',
    computed: {
        ...mapGetters([
            'getProcessResultOld',
            'getProcessResultNew',
            'getLoss',
            'getRequiredAdditionalAmountResult'
        ]),
        chartData() {
            return {
                labels: ['Ablaufergebnis', 'Verlust'],
                datasets: [
                    {
                        data: [this.getProcessResultNew, this.getLoss],
                        backgroundColor: ['#00325b', '#cbcdb9']
                    }
                ]
            };
        },
        options() {
            var that = this;
            return {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: 35
                },
                rotation: 120,
                hoverOffset: 50,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                return (
                                    context.label +
                                    ': ' +
                                    Math.round(
                                        that.getProcent(context.parsed)
                                    ) +
                                    '%'
                                );
                            }
                        }
                    }
                }
            };
        }
    },
    methods: {
        getProcent(value) {
            return (value / this.getProcessResultOld) * 100;
        }
    },
    mounted() {
        this.$refs.flick.on('changed', (flick) => {
            this.emitter.emit('index-slide', flick.index);
        });

        this.emitter.on('next-slide', () => {
            this.$refs.flick.next();
        });

        this.emitter.on('prev-slide', () => {
            this.$refs.flick.prev();
        });
    }
};
</script>

<style scoped>
.pie-panel {
    height: calc(100vh - 152px);
}
</style>
